body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Chakra Petch, sans-serif;
}

textarea {
  color: #010101;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  scrollbar-width: none;
  resize: none;
  border-radius: 85px;
  align-content: center;
  min-height: 300px;
  margin: 65px 35px;
  padding: 0 70px;
  font-family: Chakra Petch, sans-serif;
  font-size: 50px;
  font-weight: 400;
}

div {
  touch-action: manipulation;
}

textarea {
  border: 5px solid #0000;
}

textarea:focus {
  border: 5px solid #000;
  outline: none;
}

body {
  background-color: #efe4aa;
}

#instruction-text {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
}

#question-title {
  text-align: center;
  margin: 70px;
  font-size: 80px;
  font-weight: 600;
}

#main-container {
  flex-direction: column;
  display: flex;
}

#question-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#multi-answer-container {
  box-sizing: border-box;
  flex-direction: column;
  flex: 3;
  width: 100%;
  padding: 0 30px;
}

#text-answer-container {
  box-sizing: border-box;
  flex-direction: column;
  flex: 3;
  padding: 30px;
  display: none;
}

#submit-button {
  color: #fff;
  background-color: #000;
  border-radius: 85px;
  height: 135px;
  margin: 35px 65px 150px;
  font-size: 50px;
  font-weight: 500;
  overflow: hidden;
}

#choices-container {
  margin: 30px 0;
}

#send-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
}

#send-progress-bar {
  background-color: #557947;
  width: 0%;
  height: 135px;
}

.choice-text {
  background-color: #fff;
  border: 4px solid #fff;
  border-radius: 85px;
  align-items: center;
  margin: 35px;
  padding: 25px 70px;
  font-size: 50px;
  font-weight: 400;
  display: flex;
}

.choice-text.selected {
  border: 4px solid #000;
}

@keyframes progress-bar {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.animate-bar {
  animation-name: progress-bar;
  animation-duration: 10s;
  animation-timing-function: linear;
}

/*# sourceMappingURL=index.d83b4e25.css.map */
