body, 
html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Chakra Petch", sans-serif;
}

textarea {
    color: #010101;
    font-family: "Chakra Petch", sans-serif;
    font-size: 50px;
    font-weight: 400;
    margin: 65px 35px 65px 35px;
    user-select: none;
    touch-action: manipulation;
    border-radius: 85px;
    min-height: 300px;
    scrollbar-width: none;
    resize: none;
    align-content: center;
    padding: 0px 70px 0px 70px;
}

div {
    touch-action: manipulation;
}

textarea {
    border: 5px solid transparent;
}

textarea:focus {
    border: 5px solid black;
    outline: none;
}

body {
    background-color: #EFE4AA;
}

#instruction-text {
    font-size: 40px;
    text-align: center;
    font-weight: 500
}

#question-title {
    font-size: 80px;
    font-weight: 600;
    text-align: center;
    margin: 70px;
}

#main-container {
    display: flex;
    flex-direction: column;
}

#question-container {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

#multi-answer-container {
    width: 100%;
    padding: 0px 30px 0px 30px;
    box-sizing: border-box;
    flex-direction: column;
    flex: 3;
}

#text-answer-container {
    display: none;
    padding: 30px;
    box-sizing: border-box;
    flex-direction: column;
    flex: 3;
}

#submit-button {
    color: #fff;
    background-color: #000;
    border-radius: 85px;
    height: 135px;
    margin: 35px 65px 150px 65px;
    font-size: 50px;
    font-weight: 500;
    overflow: hidden;
}

#choices-container {
    margin: 30px 0px 30px 0px;
}

#send-text {
    position: absolute;
    transform: translate(-50%, 50%);
    left: 50%;
}

#send-progress-bar {
    height: 135px;
    width: 0%;
    background-color: #557947;
}

.choice-text {
    display: flex;
    font-size: 50px;
    font-weight: 400;
    background-color: white;
    border-radius: 85px;
    margin: 35px;
    padding: 25px 70px 25px 70px;
    align-items: center;
    border-color: white;
    border-width: 4px;
    border-style: solid;
}

.choice-text.selected {
    border-color: black;
    border-width: 4px;
    border-style: solid;
}

@keyframes progress-bar {
    0%   { width: 100% }
    100% { width: 0% }
}

.animate-bar {
    animation-name: progress-bar;
    animation-duration: 10s;
    animation-timing-function: linear;
}